import { render, staticRenderFns } from "./AffiliateProgram.vue?vue&type=template&id=68125ff4&scoped=true&"
import script from "./AffiliateProgram.vue?vue&type=script&lang=js&"
export * from "./AffiliateProgram.vue?vue&type=script&lang=js&"
import style0 from "./AffiliateProgram.vue?vue&type=style&index=0&id=68125ff4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68125ff4",
  null
  
)

export default component.exports