<template>
  <b-row>
    <b-col
      cols="6"
      class="mx-auto"
    >
      <b-card class="mt-3">
        <b-card-text class="px-5 py-2">
          <h1 class="font-weight-bolder mb-2 text-primary text-center">
            The Affiliate Program
          </h1>
          <p class="text-center">
            Begin earning revenue by promoting our platform. For each new client who registers using your referral link,
            you will receive a 10%  revenue share during their first year, followed by a 3% lifetime revenue share on all their subsequent purchases.
            Alternatively, if you promote the CPA referral link, you can receive a fixed CPA payout ranging between €200 and €400,
            depending on the subscription plan chosen by the referred client. This presents a lucrative opportunity for you
            to generate income while supporting our platform's growth.
          </p>
          <div class="text-center">
            <b-button
              variant="primary"
              class="mt-1"
            >
              Join The Program
            </b-button>
          </div>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.ch-title {
  position: relative;
  top: -2px;
}

.warning-section {
  background: #f44336;
  color: #fff;
}
</style>
